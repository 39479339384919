import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Portugal",
  "subtitle": "May 2015",
  "category": "Europe"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "general"
    }}>{`General`}</h2>
    <h3 {...{
      "id": "food"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bacalhau"
        }}>{`Bacalhau`}</a>{` - Dried and salted cod`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Bica_(coffee)"
        }}>{`Bica`}</a>{` - Similar to espresso`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Pastel_de_nata"
        }}>{`Pastel de nata`}</a>{` - If only I could eat this everyday!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Port_wine"
        }}>{`Port wine`}</a>{` - Sometimes also an ice cream flavor`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Saloio"
        }}>{`Saloio cheese`}</a>{` - Sheep’s milk cheese with no salt`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Serra_da_Estrela_cheese"
        }}>{`Serra de Estrela cheese`}</a>{` - Sheep’s milk`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Tripas_%C3%A0_moda_do_Porto"
        }}>{`Tripas à moda do Porto`}</a>{` - Tripe dish`}</li>
      <li parentName="ul">{`Tuna / Sardines`}</li>
    </ul>
    <h2 {...{
      "id": "lisbon"
    }}>{`Lisbon`}</h2>
    <h3 {...{
      "id": "food-1"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dcShgZi3AirqdXP79"
        }}>{`Cantinho do Aziz`}</a>{` - Highly recommend this Mozambican restaurant`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/gpBKKUx4VcG5vihX6/"
        }}>{`Gelados Santini`}</a>{` - Iconic ice cream parlour with long history`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/PmBTbyMw9sTvoy8Q9"
        }}>{`Pateis de Balem`}</a>{` - There's a side entrance if the front is too chaotic`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QNT8gfeFh16jUGG4A"
        }}>{`Mini Bar Teatro`}</a></li>
    </ul>
    <h3 {...{
      "id": "things-to-do"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/2spfB2FeZ2MGVbZc7"
        }}>{`Mosteiro dos Jerónimos`}</a>{` (`}<a parentName="li" {...{
          "href": "https://whc.unesco.org/en/list/263/"
        }}>{`UNESCO`}</a>{`)`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/9XvMotgJfED8wo5V9"
            }}>{`Torre de Belém`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/mEg8kf7MFo3Sn2Je6"
            }}>{`Jardim da Praça do Império`}</a>{` (same area)`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://maps.app.goo.gl/TjS5Q8QhpLCubKHm8"
            }}>{`Padrão dos Descobrimentos`}</a>{` (same area)`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/Us2eJkvQeEkDdEh88"
        }}>{`Museu de Arte Contemporânea`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/kGW1itJFgtHnBBhj8"
        }}>{`Museu Nacional do Azulejo`}</a>{` - Tiles were beautiful, highly recommended!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4H94zvJkf4tbvSty6"
        }}>{`Palace Fronteira`}</a>{` - Tour was short but the `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Palace_of_the_Marquises_of_Fronteira"
        }}>{`history`}</a>{` was interesting`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/KfiVrYDNmfkW2w45A"
        }}>{`Parque Eduardo VII`}</a>{` - Highly recommend viewing from the `}<a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ybk39V2wZKA6GaXr5"
        }}>{`observation deck`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/4KVKFjQzzmfrv3dk6"
        }}>{`Tram 28`}</a></li>
    </ul>
    <h4 {...{
      "id": "shopping"
    }}>{`Shopping`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vNqwPpdeqtEQzKc97"
        }}>{`Typographia`}</a>{` - Interesting T-shirts`}</li>
    </ul>
    <h2 {...{
      "id": "sintra"
    }}>{`Sintra`}</h2>
    <ul>
      <li parentName="ul">{`Only did a day trip here but would have liked to stay longer`}</li>
    </ul>
    <h3 {...{
      "id": "food-2"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/d2ojpXuGW4x2PCdP9"
        }}>{`Piriquita II`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/c8DogVmMUFKFnew27"
        }}>{`Becco de Sintra`}</a>{` - A local recommended it, was so-so`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-1"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/hwTRYz85YGASG4FE6"
        }}>{`Quinta da Regaleira`}</a>{` (`}<a parentName="li" {...{
          "href": "https://whc.unesco.org/en/list/723/"
        }}>{`UNESCO`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://goo.gl/maps/nZeFJaizVtYGd6j6A"
        }}>{`Parque da Pena`}</a>{` - €14 entrance fee`}</li>
    </ul>
    <h2 {...{
      "id": "porto"
    }}>{`Porto`}</h2>
    <h3 {...{
      "id": "food-3"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/NJV2NyMXVdSHVHFY6"
        }}>{`Arcadia`}</a>{` - Chocolate and macaroons kind of shop.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/dDdZZxChdiMXoKrX9"
        }}>{`Cafeina`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/H4v88GTQ2av5kpiZ7"
        }}>{`Restaurante Antunes`}</a>{` - The Pernil de Porco (pork knuckle) was filling even for 2!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/QXgc82zbyxvkeVL96"
        }}>{`Restaurante Flor dos Congregados`}</a>{` - Tucked in an alleyway but the food was good`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-2"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/6p36oRPot6eCgDAr8"
        }}>{`Graham’s Port Lodge`}</a>{` - Cellar tour was different from the Douro Valley vineyard one and also great views nearby!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YrwuJQZCs7R8i9BR7"
        }}>{`Luís I Bridge`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Dom_Lu%C3%ADs_I_Bridge"
        }}>{`wiki`}</a>{`)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/CcScToQcme84RFmy6"
        }}>{`Mosteiro da Serra do Pilar`}</a>{` - The hill here has amazing views`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/tZwhyrZKxAz1MskM8"
        }}>{`Palácio da Bolsa`}</a>{` - Guided tour only but the place was beautiful`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/YB6QimSCNAMKXYkP9"
        }}>{`Porto Cathedral`}</a>{` (`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Porto_Cathedral"
        }}>{`wiki`}</a>{`)`}</li>
    </ul>
    <h2 {...{
      "id": "douro-valley"
    }}>{`Douro Valley`}</h2>
    <ul>
      <li parentName="ul">{`I did it by train which left around 9:00 and got back around 21:00 (including a transfer)`}</li>
      <li parentName="ul">{`A boat tour or a hired car would be an easier way to explore this area`}</li>
    </ul>
    <h3 {...{
      "id": "food-4"
    }}>{`Food`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/ySqwAnrSVQLYwGnr6"
        }}>{`Vela Douro restaurant`}</a>{` - Limited opening times`}</li>
    </ul>
    <h3 {...{
      "id": "things-to-do-3"
    }}>{`Things to do`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/7DULxomgm3qYTYRx6"
        }}>{`Quinta do Bomfim`}</a>{` - Part of the Symington vineyards. I toured this before the official opening but it was very nice, highly recommended!`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://maps.app.goo.gl/vEgpoddHUHyWz2vq8"
        }}>{`Magnifico Douro`}</a>{` - Very scenic and relaxing 2 hour Rabelo boat ride with english audio guide`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      